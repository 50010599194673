/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("bootstrap/dist/js/bootstrap.min.js");
require("bootstrap/dist/css/bootstrap.min.css");
require("@popperjs/core/dist/umd/popper.min.js");
require("./src/styles/languageSwitcher.css");
require("./src/styles/timeline.css");
require("./src/styles/font-face.css");
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (
    !Boolean(location?.pathname.split("/news")[1]) ||
    location?.pathname.split("/news").length === 1 ||
    location?.pathname.split("/news")[1] === "/"
  ) {
    return true;
  } else {
    const currentPosition = getSavedScrollPosition({ pathName: "/news" });
    return false;
  }
};

exports.onRouteUpdate = (location) => {
  if (location.location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.location.hash}`).scrollIntoView();
    }, 0);
  }
};
